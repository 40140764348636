// ** React Imports
import { useContext } from 'react'

// ** Next Imports

// ** MUI Imports
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

// ** Icons Imports
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded'
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded'

// ** Third Party Imports
import { TooltipRenderProps } from 'react-joyride'

// ** Axios

// ** Hook Imports
import { AuthContext } from 'src/context/AuthContext'
import useFetch from 'src/hooks/useFetch'
import useSWRMutation from 'swr/mutation'

// ** Configs
import endPoint from 'src/configs/endpoints/riwayatHidupEndPoints'

// ** Custom Imports
import myToast from 'src/components/myToast'

const CustomReactJoyCorrection = ({ step, tooltipProps, skipProps }: TooltipRenderProps) => {
  const auth = useContext(AuthContext)
  const { ReqWithPayload } = useFetch()

  const { trigger } = useSWRMutation(`${process.env.NEXT_PUBLIC_BASE_PATH}/api/reqHandler/`, ReqWithPayload)

  const handleStartCorection = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    skipProps.onClick(event)
    step.data.setOpenModal(true)
  }

  const handleSayaSudahPerbaiki = (event: React.MouseEvent<HTMLElement>) => {
    const newCekRiwayatHidupJSON = step.data.cekRiwayatHidupJSON.map((item: { target: string }) =>
      item.target === step.data.row.target ? { ...item, status: 'pending' } : item
    )

    const payload = {
      url: `${endPoint.pelamarSetCekRiwayatHidupEndpoint}/${auth.user?.id}`,
      method: 'PATCH',
      body: newCekRiwayatHidupJSON
    }

    const myPromise = new Promise<string>((resolve, reject) => {
      trigger(payload, {
        onSuccess: data => {
          if (data.success) {
            resolve('OK')
            step.data.mutateIdentitasPelamar()
            handleStartCorection(event)
          } else {
            reject(data.message ? data.message : 'Unknown Error')
          }
        },
        onError: () => reject('Unknown Error')
      })
    })

    myToast(myPromise, 'Simpan Data Sukses!', undefined, 'Simpan Data Gagal!')
  }

  return (
    <Box
      {...tooltipProps}
      sx={{
        maxWidth: 500,
        minWidth: 300,
        overflow: 'hidden',
        borderRadius: 1,
        backgroundColor: theme => theme.palette.background.paper,
        border: theme => `2px solid ${theme.palette.error.main}`
      }}
    >
      <Box>
        <Box sx={{ px: 8, mb: 2, mt: 4 }}>{step.data.row.content}</Box>
        <Box sx={{ my: 4, mx: 8, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
          {step.data.row.status === 'new' && (
            <>
              <ErrorOutlineRoundedIcon color='error' sx={{ width: 16, height: 16, mr: 0.5 }} />
              <Typography variant='caption'>Perlu Anda perbaiki</Typography>
            </>
          )}
          {step.data.row.status === 'pending' && (
            <>
              <AccessTimeRoundedIcon color='info' sx={{ width: 16, height: 16, mr: 0.5 }} />
              <Typography variant='caption'>Menunggu konfirmasi Panitia</Typography>
            </>
          )}
          {step.data.row.status === 'done' && (
            <>
              <TaskAltRoundedIcon color='success' sx={{ width: 16, height: 16, mr: 0.5 }} />
              <Typography variant='caption'>Koreksi Benar</Typography>
            </>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          px: 4,
          py: 2,
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row-reverse',
          alignItems: 'center',
          backgroundColor: theme => theme.myColor?.dashboardMenuBg
        }}
      >
        <Button
          {...skipProps}
          onClick={handleStartCorection}
          color='error'
          variant='contained'
          size='small'
          sx={{ borderRadius: 4, color: theme => theme.myColor?.dashboardMenuIcon, fontWeight: 600 }}
        >
          Tutup
        </Button>

        <Box>
          <Button
            onClick={handleSayaSudahPerbaiki}
            variant='outlined'
            size='small'
            color='success'
            disabled={step.data.row.status !== 'new'}
            sx={{
              width: 'max-content',
              border: theme => `1.5px solid ${theme.palette.success.main}`,
              borderRadius: 4,
              '&:hover': {
                border: theme => `1.5px solid ${theme.palette.success.main}`,
                color: theme => theme.palette.success.main,
                backgroundColor: theme => `${theme.palette.success.light} !important`
              },
              textTransform: 'none !important'
            }}
          >
            <DoneAllRoundedIcon sx={{ mr: 1, width: 16, height: 16 }} />
            Sudah diperbaiki
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default CustomReactJoyCorrection
