// ** React Imports
import { ReactNode, useContext, useEffect, useState } from 'react'

// ** Next Imports
import { useRouter } from 'next/router'

// ** Hooks Import
import { AuthContext } from 'src/context/AuthContext'

// ** Configs
import authConfig from 'src/configs/endpoints/authEndPoints'

// ** Types
import type { ACLObj } from 'src/configs/acl'

// ** Spinner Import
import Spinner from 'src/components/Spinner'

import AclGuard from 'src/@core/components/auth/AclGuard'

type GuardProps = {
  authGuard: boolean
  guestGuard: boolean
  children: ReactNode
  aclAbilities: ACLObj
}

const Guard = ({ children, authGuard, guestGuard, aclAbilities }: GuardProps) => {
  const auth = useContext(AuthContext)
  const router = useRouter()

  const { id } = router.query

  const combinedId = Array.isArray(id) ? id.join('/') : id

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!router.isReady) {
      return
    }

    if (guestGuard && !authGuard) {
      // Guest OK, LoggedIn NO
      if (auth.user != null || window.localStorage.getItem(authConfig.storageKeyName)) {
        // if (auth.user !== null) {
        router.replace(auth.getHomeRoute(`/${combinedId}` || ''))
      } else {
        setLoading(false)
      }
    }

    // else if (!guestGuard && authGuard) {
    //   // Guest NO, LoggedIn OK
    //   if (auth.user === null && !window.localStorage.getItem('userData')) {
    //     // router.push('/login')
    //   }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady])

  if (!guestGuard && authGuard) {
    return (
      <>
        <AclGuard aclAbilities={aclAbilities} guestGuard={guestGuard}>
          {children}
        </AclGuard>
      </>
    )
  } else if (guestGuard && !authGuard) {
    return (
      <>
        {loading && <Spinner />}
        {children}
      </>
    )
  } else {
    return <>{children}</>
  }
}

export default Guard
