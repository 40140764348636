// ** React Imports

// ** Next Imports

// ** MUI Imports
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'

// ** Icons Imports

// ** Third Party Imports
import { TooltipRenderProps } from 'react-joyride'

// ** Axios

// ** Hook Imports
import { useSettings } from 'src/@core/hooks/useSettings'

// ** Configs

// ** Custom Imports

const CustomReactJoyTooltips = ({
  backProps,
  index,
  isLastStep,
  primaryProps,
  step,
  tooltipProps,
  size,
  skipProps
}: TooltipRenderProps) => {

  const { settings } = useSettings()

  return (
    <Box {...tooltipProps}
      sx={{
        maxWidth: 500,
        minWidth: 300,
        overflow: 'hidden',
        borderRadius: 1,
        backgroundColor: theme => theme.palette.background.paper,
      }}>
      {step.title &&
          <Typography variant='h6'
            sx={{
              px: 8, mt: 4, mb: 4,
              fontWeight: 800,
              color: theme => theme.myColor?.colorToggle,
            }}>
            {step.title}
          </Typography>
      }
      {step.content &&
        <Box sx={{ px: 8, mb: 4, mt: step.title ? 0 : 4, }}>
          {step.content}
        </Box>
      }
      <Box
        sx={{
          px: 4, py: 2,
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row-reverse',
          alignItems: 'center',
          backgroundColor: theme => theme.myColor?.dashboardMenuBg,
        }}>
        <ButtonGroup variant='contained' size='small' sx={{ borderRadius: 4, }}>
          {index > 0 && !isLastStep &&
            <Button {...backProps} color={settings.mode == 'light' ? 'primary' : 'secondary'} sx={{ borderRadius: 4, color: theme => theme.myColor?.dashboardMenuIcon, fontWeight: 600, }}>
              Back
            </Button>
          }
          <Button {...primaryProps} disableFocusRipple color={settings.mode == 'light' ? 'primary' : 'secondary'} sx={{ borderRadius: 4, color: theme => theme.myColor?.dashboardMenuIcon, fontWeight: 600, }}>
            {isLastStep ? `Close` : `Next`}
          </Button>
        </ButtonGroup>
        <Typography variant='subtitle2'
          sx={{
            px: 4,
            fontWeight: 800,
            color: theme => theme.myColor?.colorToggle,
          }}>
          {`Step ${index + 1} of ${size}`}
        </Typography>
        <Box>
          {!isLastStep &&
            <Button {...skipProps} variant='text' size='small' color={settings.mode == 'light' ? 'primary' : 'secondary'} sx={{ borderRadius: 4, }}>
              Skip
            </Button>
          }
        </Box>
      </Box>
    </Box>
  )
}

export default CustomReactJoyTooltips
