// ** React Imports
import { useContext } from 'react'

// ** Next Imports

//** MUI Imports
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

// Icon Imports
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded'
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded'
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded'

// ** Third Party Imports
import { Step } from 'react-joyride'

// ** Axios

// ** Hook Imports
import { AuthContext } from 'src/context/AuthContext'
import useFetch from 'src/hooks/useFetch'
import { KeyedMutator } from 'swr'
import useSWRMutation from 'swr/mutation'

// ** Custom Imports
import myToast from 'src/components/myToast'

// ** Config
import endPoint from 'src/configs/endpoints/riwayatHidupEndPoints'

// ** Type
import { CekRiwayatHidupJSON } from 'src/types/pelamar-identitas'

const CustomodalCorrection = ({
  openModal,
  setOpenModal,
  setCorectionStart,
  setSteps,
  cekRiwayatHidupJSON,
  mutateIdentitasPelamar
}: {
  openModal: boolean
  setOpenModal: (open: boolean) => void
  setCorectionStart: (open: boolean) => void
  setSteps: (steps: Step[]) => void
  cekRiwayatHidupJSON: CekRiwayatHidupJSON[]
  mutateIdentitasPelamar: KeyedMutator<any>
}) => {
  // ** Hooks
  const { ReqWithPayload } = useFetch()
  const auth = useContext(AuthContext)

  const { trigger } = useSWRMutation(`${process.env.NEXT_PUBLIC_BASE_PATH}/api/reqHandler/`, ReqWithPayload)

  const handleClose = () => {
    setOpenModal(!open)
  }

  const handleStartCorection = (row: CekRiwayatHidupJSON) => {
    const step: Step = {
      data: { cekRiwayatHidupJSON, row, mutateIdentitasPelamar, setOpenModal },
      title: row.title,
      content: (
        <Box>
          <Box sx={{ px: 8, mb: 2, mt: 4 }}>{row.content}</Box>
          <Box sx={{ my: 4, mx: 8, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
            {row.status === 'new' && (
              <>
                <ErrorOutlineRoundedIcon color='error' sx={{ width: 16, height: 16, mr: 0.5 }} />
                <Typography variant='caption'>Perlu Anda perbaiki</Typography>
              </>
            )}
            {row.status === 'pending' && (
              <>
                <AccessTimeRoundedIcon color='info' sx={{ width: 16, height: 16, mr: 0.5 }} />
                <Typography variant='caption'>Menunggu konfirmasi Panitia</Typography>
              </>
            )}
            {row.status === 'done' && (
              <>
                <TaskAltRoundedIcon color='success' sx={{ width: 16, height: 16, mr: 0.5 }} />
                <Typography variant='caption'>Koreksi Benar</Typography>
              </>
            )}
          </Box>
        </Box>
      ),
      placement: 'auto',
      target: row.target,
      disableBeacon: true
    }

    setSteps([step])
    setCorectionStart(true)
    handleClose()
  }

  const handleSayaSudahPerbaiki = (row: CekRiwayatHidupJSON) => {
    const newCekRiwayatHidupJSON = cekRiwayatHidupJSON.map(item =>
      item.target === row.target ? { ...item, status: 'pending' } : item
    )

    const payload = {
      url: `${endPoint.pelamarSetCekRiwayatHidupEndpoint}/${auth.user?.id}`,
      method: 'PATCH',
      body: newCekRiwayatHidupJSON
    }

    const myPromise = new Promise<string>((resolve, reject) => {
      trigger(payload, {
        onSuccess: data => {
          if (data.success) {
            resolve('OK')
            mutateIdentitasPelamar()
          } else {
            reject(data.message ? data.message : 'Unknown Error')
          }
        },
        onError: () => reject('Unknown Error')
      })
    })

    myToast(myPromise, 'Simpan Data Sukses!', undefined, 'Simpan Data Gagal!')
  }

  return (
    <Dialog
      onClose={handleClose}
      open={openModal}
      fullWidth={true}
      maxWidth='md'
      sx={{
        '& .MuiPaper-root': {
          p: 4,
          pt: 8
        }
      }}
    >
      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 4,
          top: 4,
          p: 0,
          color: theme => theme.palette.error.main
        }}
      >
        <HighlightOffRoundedIcon />
      </IconButton>
      <Box>
        <Typography
          variant='h6'
          textAlign='center'
          sx={{
            mb: 4,
            fontWeight: 600,
            color: theme => theme.myColor?.colorToggle
          }}
        >
          Daftar Koreksi Anda
        </Typography>
        <Box sx={{ px: 4, display: 'flex', flexDirection: 'column', gap: 4 }}>
          <Typography variant='subtitle2' sx={{ fontWeight: 400 }}>
            Harap segera revisi riwayat hidup Anda, khususnya pada bagian-bagian yang tercantum di bawah ini, sebelum
            tanggal{' '}
            <Box component='span' sx={{ fontWeight: 600, color: theme => theme.myColor?.colorToggle }}>
              28 Juli 2024
            </Box>
            .
          </Typography>
          <Typography variant='subtitle2' sx={{ fontWeight: 400 }}>
            Setelah Anda selesai revisi, mohon klik tombol{' '}
            <Box
              component='span'
              sx={{
                fontWeight: 600,
                color: theme => theme.palette.success.main
              }}
            >
              <DoneAllRoundedIcon sx={{ mb: -0.6, mr: 1, width: 16, height: 16 }} />
              Sudah diperbaiki
            </Box>{' '}
            agar panitia dapat melakukan pemeriksaan.
          </Typography>
          <Typography variant='subtitle2' sx={{ fontWeight: 400 }}>
            Terima kasih atas perhatian dan kerjasamanya.
          </Typography>
        </Box>
        <TableContainer component={Paper}>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell align='center'>No</TableCell>
                <TableCell align='center'>Status</TableCell>
                <TableCell align='left'>Keterangan</TableCell>
                <TableCell align='center'>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cekRiwayatHidupJSON.map((row, index) => (
                <TableRow key={'corr' + index}>
                  <TableCell align='center'>{index + 1}</TableCell>
                  <TableCell align='center'>
                    {row.status === 'new' && (
                      <ErrorOutlineRoundedIcon color='error' sx={{ width: 16, height: 16, mr: 0.5 }} />
                    )}
                    {row.status === 'pending' && (
                      <AccessTimeRoundedIcon color='info' sx={{ width: 16, height: 16, mr: 0.5 }} />
                    )}
                    {row.status === 'done' && (
                      <TaskAltRoundedIcon color='success' sx={{ width: 16, height: 16, mr: 0.5 }} />
                    )}
                  </TableCell>
                  <TableCell component='th' scope='row' align='left'>
                    <Typography variant='body2' sx={{ color: theme => theme.myColor?.colorToggle, fontWeight: 600 }}>
                      {row.title}
                    </Typography>
                  </TableCell>
                  <TableCell align='center'>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
                      <Button
                        variant='outlined'
                        size='small'
                        onClick={() => handleStartCorection(row)}
                        sx={{
                          border: theme => `1.5px solid ${theme.myColor?.colorToggle}`,
                          borderRadius: 4,
                          '&:hover': {
                            border: theme => `1.5px solid ${theme.myColor?.colorToggle}`,
                            color: theme => theme.myColor?.colorToggle,
                            backgroundColor: theme => `${theme.myColor?.colorToggleLight} !important`
                          },
                          textTransform: 'none !important'
                        }}
                      >
                        Lihat
                      </Button>
                      <Button
                        variant='outlined'
                        size='small'
                        color='success'
                        disabled={row.status !== 'new'}
                        onClick={() => handleSayaSudahPerbaiki(row)}
                        sx={{
                          width: 'max-content',
                          border: theme => `1.5px solid ${theme.palette.success.main}`,
                          borderRadius: 4,
                          '&:hover': {
                            border: theme => `1.5px solid ${theme.palette.success.main}`,
                            color: theme => theme.palette.success.main,
                            backgroundColor: theme => `${theme.palette.success.light} !important`
                          },
                          textTransform: 'none !important'
                        }}
                      >
                        <DoneAllRoundedIcon sx={{ mr: 1, width: 16, height: 16 }} />
                        Sudah diperbaiki
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            mt: 4,
            mx: 4,
            px: 4,
            py: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            borderRadius: 1,
            backgroundColor: theme => theme.myColor?.colorToggleLight
          }}
        >
          <Typography variant='subtitle2' sx={{ fontWeight: 500 }}>
            Keterangan Status:
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
            <ErrorOutlineRoundedIcon color='error' sx={{ width: 16, height: 16, mr: 1 }} />
            <Typography variant='caption'>Perlu Anda perbaiki</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
            <AccessTimeRoundedIcon color='info' sx={{ width: 16, height: 16, mr: 1 }} />
            <Typography variant='caption'>Menunggu konfirmasi Panitia</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
            <TaskAltRoundedIcon color='success' sx={{ width: 16, height: 16, mr: 1 }} />
            <Typography variant='caption'>Koreksi Benar</Typography>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}

export default CustomodalCorrection
