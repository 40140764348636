// ** MUI Imports
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import Fab from '@mui/material/Fab'

// ** Icons Imports
import MenuIcon from 'mdi-material-ui/Menu'

// ** Type Import
import { Settings } from 'src/@core/context/settingsContext'

// ** Components
import ModeToggler from 'src/layouts/components/shared/ModeToggler'
import UserDropdown from 'src/layouts/components/UserDropdown'

import Onboarding from 'src/components/navbar/OnBoarding'
import CorectionBoarding from 'src/components/navbar/CorectionBoarding'

// import NotificationDropdown from 'src/layouts/components/shared/NotificationDropdown'

interface Props {
  hidden: boolean
  settings: Settings
  toggleNavVisibility: () => void
  saveSettings: (values: Settings) => void
}

const AppBarContent = (props: Props) => {
  // ** Props
  const { hidden, settings, saveSettings, toggleNavVisibility } = props

  // ** Hooks
  const theme = useTheme()
  const hiddenMd = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', pt: 4 }}>
      <Box className='actions-left' sx={{ display: 'flex', alignItems: 'center' }}>
        {hidden && (
          <Fab
            size='medium'
            variant='extended'
            onClick={toggleNavVisibility}
            sx={{
              p: 0,
              backgroundColor: `${theme.myColor?.dashboardMenuIcon} !important`,
              boxShadow: 4,
              '&:hover': {
                transform: `scale(1.1)`,
                transition: 'transform .25s ease-in-out'
              }
            }}
          >
            <MenuIcon fontSize='small' sx={{ color: theme => theme.myColor?.colorToggle }} />
          </Fab>
        )}
      </Box>

      <Stack spacing={4} direction='row'>
        <ModeToggler settings={settings} saveSettings={saveSettings} hidden={hiddenMd} />
        <CorectionBoarding />
        <Onboarding />
        {/* <NotificationDropdown /> */}
        <UserDropdown settings={settings} />
      </Stack>
    </Box>
  )
}

export default AppBarContent
