// ** React Imports
import { useContext, useState } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material'

// ** Next Imports
import { useRouter } from 'next/router'

// ** Icons Imports
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'

// ** Third Party Imports
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride'

// ** Custom Imports
import CustomReactJoyCorrection from 'src/components/CustomReactJoyCorrection'
import CustomModalCorrection from 'src/components/riwayat-hidup/CustomModalCorrection'

// ** Contexts
import { useCorectionStart } from 'src/context/CorectionContext'

// ** Configs
import endPoint from 'src/configs/endpoints/riwayatHidupEndPoints'

// ** Hook Import
import useFetch from 'src/hooks/useFetch'
import useSWR from 'swr'
import { AuthContext } from 'src/context/AuthContext'

// ** Type
import { PelamarIdentitasResponse, CekRiwayatHidupJSON } from 'src/types/pelamar-identitas'

const CorectionBoarding = () => {
  // ** Hooks
  const { FetchWithPayload } = useFetch()
  const theme = useTheme()
  const { corectionStart, setCorectionStart, showCorectionModal, setCorectionShowModal } = useCorectionStart()
  const router = useRouter()
  const auth = useContext(AuthContext)

  const [steps, setSteps] = useState<Step[]>([])
  const { id } = router.query

  const payloadPelamarIdentitas = {
    url: `${endPoint.pelamarGetEndpoint}/${id ? id : auth.user?.id}`,
    method: 'GET'
  }
  const {
    data: dataIdentitasPelamar,
    isLoading: isLoadingIdentitas,
    mutate: mutateIdentitasPelamar
  } = useSWR<PelamarIdentitasResponse>(
    [`${process.env.NEXT_PUBLIC_BASE_PATH}/api/reqHandler/`, payloadPelamarIdentitas],
    FetchWithPayload
  )

  const handleShowModalCorrection = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setCorectionShowModal(true)
  }

  const handleGoToCorrection = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    router.push('/pelamar/riwayat-hidup')
  }

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data

    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED]

    if (finishedStatuses.includes(status)) {
      setCorectionStart(false)
    }
  }

  return (
    <>
      {!isLoadingIdentitas &&
        dataIdentitasPelamar &&
        dataIdentitasPelamar?.data &&
        dataIdentitasPelamar?.data?.cek_riwayat_hidup_json &&
        dataIdentitasPelamar?.data?.cek_riwayat_hidup_json != '[]' &&
        auth.user?.role_id != '5' && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {router.pathname.includes('pelamar/riwayat-hidup') ? (
              <IconButton
                size='small'
                sx={{
                  width: 40,
                  height: 40,
                  pb: 1.5,
                  animation: 'pulse-error 2s infinite'
                }}
                onClick={handleShowModalCorrection}
                id='help-icon'
              >
                <WarningRoundedIcon sx={{ width: 30, height: 30, color: theme => theme.palette.error.main }} />
              </IconButton>
            ) : (
              <IconButton
                size='small'
                sx={{
                  width: 40,
                  height: 40,
                  animation: 'pulse-error 2s infinite'
                }}
                id='help-icon'
                onClick={handleGoToCorrection}
              >
                <WarningRoundedIcon sx={{ width: 30, height: 30, color: theme => theme.palette.error.main }} />
              </IconButton>
            )}

            <Joyride
              callback={handleJoyrideCallback}
              tooltipComponent={CustomReactJoyCorrection}
              run={corectionStart}
              steps={steps}
              continuous
              disableCloseOnEsc
              disableOverlayClose
              hideCloseButton
              scrollToFirstStep
              showSkipButton
              scrollOffset={200}
              styles={{
                options: {
                  zIndex: 10000,
                  overlayColor: theme.myColor?.backdropColor,
                  arrowColor: theme.palette.error.main
                }
              }}
            />

            {!isLoadingIdentitas &&
              dataIdentitasPelamar &&
              dataIdentitasPelamar?.data &&
              dataIdentitasPelamar?.data?.cek_riwayat_hidup_json && (
                <CustomModalCorrection
                  openModal={showCorectionModal}
                  setOpenModal={setCorectionShowModal}
                  setCorectionStart={setCorectionStart}
                  setSteps={setSteps}
                  cekRiwayatHidupJSON={
                    JSON.parse(dataIdentitasPelamar?.data.cek_riwayat_hidup_json) as CekRiwayatHidupJSON[]
                  }
                  mutateIdentitasPelamar={mutateIdentitasPelamar}
                />
              )}
          </Box>
        )}
    </>
  )
}

export default CorectionBoarding
