// ** React Imports

// ** Next Imports

// ** MUI Imports

// ** Icons Imports

// ** Third Party Imports
import LZString from 'lz-string'

// ** Axios

// ** Hook Imports

// ** Configs

// ** Custom Imports

const encrypt = (payload: string) => {
  return LZString.compressToUTF16(payload)
}

const decrypt = (encryptedMessage: string) => {
  return LZString.decompressFromUTF16(encryptedMessage)
}

const encryptURI = (payload: string) => {
  return LZString.compressToEncodedURIComponent(payload)
}

const decryptURI = (encryptedMessage: string) => {
  return LZString.decompressFromEncodedURIComponent(encryptedMessage)
}

const useCrypto = () => {
  return { encryptURI, decryptURI, encrypt, decrypt }
}

export default useCrypto
