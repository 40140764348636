// ** React Imports

// ** Next Import
import Link from 'next/link'

// ** MUI Components
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Box, { BoxProps } from '@mui/material/Box'

// ** Icons Import

// ** CSS import
import styles from './NotAuthorized401.module.css'

// ** Styled Components
const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '90vw'
  }
}))

const NotAuthorized401 = ({ redirectLink = '' }: { redirectLink?: string }) => {
  return (
    <Box sx={{ p: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
      <Box sx={{ position: 'relative' }}>
        <Box className={styles.bubble} />
        <Box className={styles.bubble} />
        <Box className={styles.bubble} />
        <Box className={styles.bubble} />
        <Box className={styles.bubble} />
        <Box className={styles.bubble} />
        <Box className={styles.bubble} />
        <Box className={styles.bubble} />
        <Box className={styles.bubble} />
        <Box className={styles.bubble} />
      </Box>
      <BoxWrapper sx={{ mb: 4 }}>
        <Typography variant='h1' sx={{ ml: -14, mb: 2.5, fontWeight: 800, fontSize: '10rem !important' }}>
          401
        </Typography>
        <Typography
          variant='h5'
          sx={{ mb: 2.5, letterSpacing: '0.18px', fontWeight: 600, fontSize: '1.5rem !important' }}
        >
          Not Authorized
        </Typography>
        <Typography variant='body2'>You don&prime;t have permisson to access this Page.</Typography>
      </BoxWrapper>
      <Link passHref href={`/login${redirectLink}`}>
        <Button
          component='a'
          variant='contained'
          sx={{
            px: 5.5,
            borderRadius: 4,
            background: 'radial-gradient(circle at 20% 20%, #4e96e7 0%, #1352b8 65%, #041e67 100%)',
            boxShadow: '10px 10px 30px rgb(0 0 0 /30%)',
            '&:hover': {
              background: 'radial-gradient(circle at 20% 20%, #4e96e7 0%, #1352b8 45%, #041e67 90%)',
              boxShadow: '10px 10px 30px rgb(0 0 0 /50%)'
            }
          }}
        >
          Log In
        </Button>
      </Link>
    </Box>
  )
}

export default NotAuthorized401
