const dashboard = (role_id: string) => {
  if (role_id == '4' || role_id == '5') {
    return '/admin/dashboard/'
  } else if (role_id == '2') {
    return '/pelamar/dashboard/'
  } else if (role_id == '9') {
    return '/pewawancara/dashboard/'
  } else {
    return '/home/'
  }
}

export default dashboard
