// ** React Imports
import { ReactNode } from 'react'

// ** Next Import

// ** MUI Components
import Box from '@mui/material/Box'

// ** Icons Import

// ** Layout Import
import BlankLayout from 'src/@core/layouts/BlankLayout'

// ** Custom Import
import Error from 'src/components/401/NotAuthorized401'

const NotAuthorized401 = ({ redirectLink = '' }: { redirectLink?: string }) => {
  return (
    <Box className='content-center'>
      <Error redirectLink={redirectLink} />
    </Box>
  )
}

NotAuthorized401.getLayout = (page: ReactNode) => <BlankLayout>{page}</BlankLayout>

NotAuthorized401.authGuard = false

export default NotAuthorized401
