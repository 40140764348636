// ** React Imports
import { useContext } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material'

// ** Next Imports
import { useRouter } from 'next/router'

// ** Icons Imports
import ContactSupportRoundedIcon from '@mui/icons-material/ContactSupportRounded'

// ** Third Party Imports
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride'

// ** Custom Imports
import CustomReactJoyTooltips from 'src/components/CustomReactJoyTooltips'

// ** Contexts
import { useTourStart } from 'src/context/TourContext'

// ** Configs
import endPoint from 'src/configs/endpoints/riwayatHidupEndPoints'

// ** Hook Import
import { useSettings } from 'src/@core/hooks/useSettings'
import useFetch from 'src/hooks/useFetch'
import useSWRMutation from 'swr/mutation'
import { AuthContext } from 'src/context/AuthContext'

const OnBoarding = () => {
  // ** Hooks
  const { ReqWithPayload } = useFetch()
  const theme = useTheme()
  const { tourStart, setTourStart } = useTourStart()
  const router = useRouter()
  const { settings } = useSettings()
  const auth = useContext(AuthContext)

  const { trigger } = useSWRMutation(`${process.env.NEXT_PUBLIC_BASE_PATH}/api/reqHandler/`, ReqWithPayload)

  const tombolBantuan: Step = {
    title: 'Tombol Bantuan',
    content: (
      <Box>
        <Typography>
          Untuk menampilkan bantuan ini kembali, Anda dapat menggunakan{' '}
          <Box component='span' sx={{ fontWeight: 600, color: theme => theme.myColor?.colorToggle }}>
            Tombol Bantuan
          </Box>{' '}
          berikut ini.
        </Typography>
      </Box>
    ),
    placement: 'right',
    target: '#help-icon'
  }

  const steps: Step[] = router.pathname.includes('pelamar/dashboard')
    ? [
        {
          title: 'E-Recruitment Bankaltimtara',
          content: (
            <Typography>
              Yuk kenalan dengan menu yang ada di{' '}
              <Box component='span' sx={{ fontWeight: 600, color: theme => theme.myColor?.colorToggle }}>
                Portal Rekrutmen Bankaltimtara
              </Box>
              .
            </Typography>
          ),
          placement: 'center',
          target: 'body'
        },
        {
          title: 'Dashboard',
          content: (
            <Typography>
              Akses halaman utama dengan menggunakan menu{' '}
              <Box component='span' sx={{ fontWeight: 600, color: theme => theme.myColor?.colorToggle }}>
                Dashboard
              </Box>
              .
            </Typography>
          ),
          placement: 'right',
          target: '#dashboardNav'
        },
        {
          title: 'Riwayat Hidup',
          content: (
            <Box>
              <Typography>
                Manfaatkan fitur{' '}
                <Box component='span' sx={{ fontWeight: 600, color: theme => theme.myColor?.colorToggle }}>
                  Curriculum Vitae Online
                </Box>{' '}
                di menu{' '}
                <Box component='span' sx={{ fontWeight: 600, color: theme => theme.myColor?.colorToggle }}>
                  Riwayat Hidup
                </Box>{' '}
                kami. Lengkapi Riwayat Hidup Anda untuk mengajukan pendaftaran dalam rekrutmen di Bankaltimtara.
              </Typography>
            </Box>
          ),
          placement: 'right',
          target: '#riwayatHidupNav'
        },
        {
          title: 'Lamaran Saya',
          content: (
            <Box>
              <Typography>
                Pantau proses rekrutmen yang Anda ikuti melalui menu{' '}
                <Box component='span' sx={{ fontWeight: 600, color: theme => theme.myColor?.colorToggle }}>
                  Lamaran Saya
                </Box>
                .
              </Typography>
            </Box>
          ),
          placement: 'right',
          target: '#lamaranNav'
        },
        {
          title: 'Rekrutmen Bankaltimtara',
          content: (
            <Box>
              <Typography>
                Temukan rekrutmen yang sedang dipublikasikan dan ikuti proses pendaftaran melalui menu{' '}
                <Box component='span' sx={{ fontWeight: 600, color: theme => theme.myColor?.colorToggle }}>
                  Rekrutmen Bankaltimtara
                </Box>
                .
              </Typography>
            </Box>
          ),
          placement: 'right',
          target: '#rekrutmenNav'
        },
        tombolBantuan
      ]
    : router.pathname.includes('pelamar/riwayat-hidup')
    ? [
        {
          title: 'Riwayat Hidup',
          content: (
            <Typography>
              Ayo berkenalan dengan Fitur{' '}
              <Box component='span' sx={{ fontWeight: 600, color: theme => theme.myColor?.colorToggle }}>
                Riwayat Hidup
              </Box>{' '}
              di Portal Rekrutmen Bankaltimtara.
            </Typography>
          ),
          placement: 'center',
          target: 'body'
        },
        {
          title: 'Foto Profil',
          content: (
            <Typography>
              Perbarui foto profil Anda dengan mengklik pada {''}
              <Box component='span' sx={{ fontWeight: 600, color: theme => theme.myColor?.colorToggle }}>
                foto profil
              </Box>{' '}
              ini. Pastikan menggunakan foto profil yang terlihat formal dan profesional.
            </Typography>
          ),
          placement: 'right',
          target: '#riwayatHidupFoto'
        },
        {
          title: 'Ubah Data',
          content: (
            <Box>
              <Typography>
                Perbarui seluruh data Anda dengan menekan tombol{' '}
                <Box component='span' sx={{ fontWeight: 600, color: theme => theme.myColor?.colorToggle }}>
                  Edit (ikon Pensil)
                </Box>{' '}
                pada setiap bagian riwayat hidup.
              </Typography>
            </Box>
          ),
          placement: 'right',
          target: '#riwayatHidupEditButton'
        },

        // {
        //   title: 'Ubah Data',
        //   content: (
        //     <Box>
        //       <Typography>
        //         Berikut contoh tombol{' '}
        //         <Box component='span' sx={{ fontWeight: 600, color: theme => theme.myColor?.colorToggle }}>
        //           Edit (ikon Pensil)
        //         </Box>{' '}
        //         pada setiap bagian riwayat hidup.
        //       </Typography>
        //     </Box>
        //   ),
        //   placement: 'right',
        //   target: '#riwayatHidupEditButton1'
        // },
        {
          title: 'Tambah Data',
          content: (
            <Box>
              <Typography>
                Tambahkan data pada Riwayat Hidup Anda melalui tombol{' '}
                <Box component='span' sx={{ fontWeight: 600, color: theme => theme.myColor?.colorToggle }}>
                  Tambah (ikon Plus)
                </Box>{' '}
                pada setiap bagian riwayat hidup.
              </Typography>
            </Box>
          ),
          placement: 'left',
          target: '#riwayatHidupAddButton'
        },

        // {
        //   title: 'Tambah Data',
        //   content: (
        //     <Box>
        //       <Typography>
        //         Berikut adalah contoh tombol{' '}
        //         <Box component='span' sx={{ fontWeight: 600, color: theme => theme.myColor?.colorToggle }}>
        //           Tambah (Ikon Plus)
        //         </Box>{' '}
        //         pada setiap bagian riwayat hidup.
        //       </Typography>
        //     </Box>
        //   ),
        //   placement: 'right',
        //   target: '#riwayatHidupAddButton2'
        // },
        {
          title: 'Upload Berkas',
          content: (
            <Box>
              <Typography>
                Lengkapi semua Dokumen Digital Anda dengan mengklik tombol{' '}
                <Box component='span' sx={{ fontWeight: 600, color: theme => theme.myColor?.colorToggle }}>
                  Unggah
                </Box>{' '}
                berikut ini.
              </Typography>
            </Box>
          ),
          placement: 'right',
          target: '#riwayatHidupUploadButton'
        },
        {
          title: 'Data Wajib',
          content: (
            <Box>
              <Typography>
                Lengkapi semua{' '}
                <Box component='span' sx={{ fontWeight: 600, color: theme => theme.myColor?.colorToggle }}>
                  Data Wajib
                </Box>{' '}
                yang ditandai dengan simbol asterik (
                <Box component='span' sx={{ fontWeight: 600, color: theme => theme.palette.error.main }}>
                  *
                </Box>
                ) seperti berikut ini.
              </Typography>
            </Box>
          ),
          placement: 'right',
          target: '#riwayatHidupRequired'
        },

        // {
        //   title: 'Data Wajib',
        //   content: (
        //     <Box>
        //       <Typography>
        //         Berikut adalah contoh{' '}
        //         <Box component='span' sx={{ fontWeight: 600, color: theme => theme.myColor?.colorToggle }}>
        //           Data Wajib
        //         </Box>{' '}
        //         yang ditandai dengan simbol asterisk (
        //         <Box component='span' sx={{ fontWeight: 600, color: theme => theme.palette.error.main }}>
        //           *
        //         </Box>
        //         ) lainnya.
        //       </Typography>
        //     </Box>
        //   ),
        //   placement: 'right',
        //   target: '#riwayatHidupRequired2'
        // },
        {
          title: 'Kelengkapan Riwayat Hidup',
          content: (
            <Box>
              <Typography>
                Pantau kelengkapan{' '}
                <Box component='span' sx={{ fontWeight: 600, color: theme => theme.palette.primary.main }}>
                  Data Wajib
                </Box>{' '}
                &{' '}
                <Box component='span' sx={{ fontWeight: 600, color: theme => theme.palette.secondary.main }}>
                  Data Tambahan
                </Box>{' '}
                Riwayat Hidup Anda pada diagram berikut.
                <br />
                <br /> <b>Sorot</b> diagram ini untuk melihat data apa saja yang belum Anda lengkapi.
              </Typography>
            </Box>
          ),
          placement: 'right',
          target: '#completedChart',
          disableScrolling: false
        },
        {
          title: 'Finished!',
          content: (
            <Typography>
              Ingatlah untuk melengkapi semua{' '}
              <Box component='span' sx={{ fontWeight: 600, color: theme => theme.myColor?.colorToggle }}>
                data wajib (100%)
              </Box>{' '}
              pada{' '}
              <Box component='span' sx={{ fontWeight: 600, color: theme => theme.myColor?.colorToggle }}>
                Riwayat Hidup
              </Box>{' '}
              Anda sebelum mengikuti rekrutmen di Bankaltimtara.
            </Typography>
          ),
          placement: 'center',
          target: 'body'
        },
        tombolBantuan
      ]
    : [
        {
          title: 'E-Recruitment Bankaltimtara',
          content: <Typography>Mohon Maaf, tidak ada bantuan untuk halaman ini.</Typography>,
          placement: 'center',
          target: 'body'
        }
      ]

  const payload = {
    url: `${endPoint.finishedOnboardingEndpoint}/${auth.user?.id}`,
    method: 'GET'
  }

  const handlStartTour = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setTourStart(true)
  }

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data

    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED]
    const done: string[] = [STATUS.FINISHED, STATUS.SKIPPED]

    if (finishedStatuses.includes(status)) {
      setTourStart(false)
      if (done.includes(status) && router.pathname.includes('pelamar/riwayat-hidup')) {
        trigger(payload)
      }
    }
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {router.pathname.includes('pelamar/dashboard') || router.pathname.includes('pelamar/riwayat-hidup') ? (
        <IconButton
          size='small'
          sx={{
            width: 40,
            height: 40,
            animation: settings.mode == 'light' ? 'pulse-light 2s infinite' : 'pulse-dark 2s infinite'
          }}
          onClick={handlStartTour}
          id='help-icon'
        >
          <ContactSupportRoundedIcon sx={{ width: 30, height: 30, color: theme => theme.myColor?.colorToggle }} />
        </IconButton>
      ) : (
        <IconButton
          size='small'
          sx={{
            width: 40,
            height: 40
          }}
          onClick={handlStartTour}
          id='help-icon'
          disabled
        >
          <ContactSupportRoundedIcon sx={{ width: 30, height: 30, color: theme => theme.myColor?.borderColor }} />
        </IconButton>
      )}

      <Joyride
        callback={handleJoyrideCallback}
        tooltipComponent={CustomReactJoyTooltips}
        run={tourStart}
        steps={steps}
        continuous
        disableCloseOnEsc
        disableOverlayClose
        hideCloseButton
        scrollToFirstStep
        showSkipButton
        disableScrolling
        styles={{
          options: {
            zIndex: 10000,
            overlayColor: theme.myColor?.backdropColor,
            arrowColor: theme.palette.background.paper
          }
        }}
      />
    </Box>
  )
}

export default OnBoarding
