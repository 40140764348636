// ** React Imports
import { ReactNode, createContext, useEffect, useState } from 'react'

// ** Next Imports
import { useRouter } from 'next/router'

// ** MUI Imports

// ** Icons Imports

// ** Third Party Imports
import useSWRMutation from 'swr/mutation'

// ** Axios
// import axios from 'axios';

// ** Hook Imports
import useCrypto from 'src/hooks/useCrypto'
import useFetch from 'src/hooks/useFetch'

// ** Configs
import authConfig from 'src/configs/endpoints/authEndPoints'
import dashboard from 'src/configs/dashboard'

// ** Custom Imports

// ** Types
import { AuthValuesType, LoginParams, RegisterParams, UserDataType } from './types'

// ** Defaults
const defaultProvider: AuthValuesType = {
  user: null,
  loading: true,
  setUser: () => null,
  setLoading: () => Boolean,
  isInitialized: false,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  setIsInitialized: () => Boolean,
  register: () => Promise.resolve(),
  getHomeRoute: () => '',
  updateLocalStorage: () => null
}

const AuthContext = createContext(defaultProvider)

type Props = {
  children: ReactNode
}

const AuthProvider = ({ children }: Props) => {
  // ** States
  // const [user, setUser] = useState<UserDataType | null>(defaultProvider.user)
  const [user, setUser] = useState<UserDataType | null>(defaultProvider.user)
  const [loading, setLoading] = useState<boolean>(defaultProvider.loading)
  const [isInitialized, setIsInitialized] = useState<boolean>(defaultProvider.isInitialized)

  // ** Hooks
  const router = useRouter()
  const { encrypt, decrypt } = useCrypto()

  const { ReqWithPayload } = useFetch()

  const { trigger: triggerLogin } = useSWRMutation(
    `${process.env.NEXT_PUBLIC_BASE_PATH}/api/reqHandler/`,
    ReqWithPayload
  )
  const { trigger: triggerLogout } = useSWRMutation(
    `${process.env.NEXT_PUBLIC_BASE_PATH}/api/reqHandler/`,
    ReqWithPayload
  )
  const { trigger: triggerAuth } = useSWRMutation(
    `${process.env.NEXT_PUBLIC_BASE_PATH}/api/reqHandler/`,
    ReqWithPayload
  )
  const { trigger: triggerRegister } = useSWRMutation(
    `${process.env.NEXT_PUBLIC_BASE_PATH}/api/reqHandler/`,
    ReqWithPayload
  )

  useEffect(() => {
    const initAuth = async (): Promise<void> => {
      const encryptedData = window.localStorage.getItem(authConfig.storageKeyName)
      setIsInitialized(false)
      setLoading(true)

      if (encryptedData) {
        const storedData: UserDataType = JSON.parse(decrypt(encryptedData))

        const payload = {
          url: authConfig.authEndpoint,
          Authorization: `Bearer ${storedData.bearer_token}`,
          method: 'POST',
          body: { username: storedData.username }
        }

        triggerAuth(payload, {
          onSuccess(data) {
            if (data?.success) {
              setUser({ ...storedData })
              setIsInitialized(true)
            } else {
              window.localStorage.removeItem(authConfig.storageKeyName)
            }
            setLoading(false)
          },
          onError() {
            window.localStorage.removeItem(authConfig.storageKeyName)
            setLoading(false)
          }
        })
      } else {
        setLoading(false)
      }
    }

    initAuth()
  }, [decrypt, router, triggerAuth])

  const getHomeRoute = (redirectLink: string) => {
    const localUserData = window.localStorage.getItem(authConfig.storageKeyName)

    if (redirectLink != '') {

      return `/${redirectLink}`
    }

    if (localUserData) {
      const decryptedData = decrypt(localUserData)
      const objData = JSON.parse(decryptedData)

      return dashboard(objData.role_id)
    }

    return '/home/'
  }

  const HandleLogin = (
    params: LoginParams,
    successCallback: () => void,
    errorCallback: (message: string) => void,
    redirectLink?: string
  ) => {
    const payload = {
      url: authConfig.loginEndpoint,
      body: params,
      method: 'POST'
    }

    triggerLogin(payload, {
      onSuccess(data) {
        if (data.success) {
          const userData: UserDataType = data.data

          setUser({ ...userData })
          setIsInitialized(true)

          window.localStorage.setItem(authConfig.storageKeyName, encrypt(JSON.stringify({ ...userData })))
          successCallback()
          setTimeout(() => {
            router.replace(getHomeRoute(redirectLink || ''))
          }, 1000)
        } else {
          if (data.errors) errorCallback(data.errors)
          else errorCallback('unknown error, contact the administrator')
        }
      },
      onError: err => {
        errorCallback(err.message)
      }
    })

    // axios
    //   .post(authConfig.loginEndpoint, params)
    //   .then(async res => {
    //     if (res.data.data == null || res.data.data.ActiveToken == null)
    //       throw new Error(res.data.message)

    //     window.localStorage.setItem(authConfig.storageTokenKeyName, res.data.data.ActiveToken)
    //     window.localStorage.setItem(authConfig.storageActiveUserIDKeyName, res.data.data.ActiveUserID)
    //   })
    //   .then(() => {
    //     axios
    //       .post(authConfig.authEndpoint, {
    //         ActiveToken: window.localStorage.getItem(authConfig.storageTokenKeyName)!,
    //         ActiveUserID: window.localStorage.getItem(authConfig.storageActiveUserIDKeyName)!,
    //       })
    //       .then(async response => {
    //         const userData = { role: response.data.data.ActiveUserLevel, ...response.data.data }

    //         setUser(userData)
    //         window.localStorage.setItem('userData', JSON.stringify(userData))

    //         if (successCallback) successCallback()

    //         setTimeout(() => {
    //           router.replace(getHomeRoute())
    //         }, 1000)
    //       })
    //   })
    //   .catch((err) => {
    //     if (errorCallback) errorCallback(err.message)
    //   })
  }

  const handleLogout = () => {
    const payload = {
      url: authConfig.logoutEndPoint,
      Authorization: `Bearer ${user?.bearer_token}`,
      method: 'GET'
    }

    triggerLogout(payload)

    // axios
    //   .post(authConfig.logoutEndPoint, {
    //     activeToken: window.localStorage.getItem(authConfig.storageTokenKeyName)!,
    //     activeUserID: window.localStorage.getItem('UserID')!,
    //   })

    setUser(null)
    setIsInitialized(false)
    window.localStorage.removeItem(authConfig.storageKeyName)
    router.replace('/login')
  }

  const handleRegister = (
    params: RegisterParams,
    successCallback: () => void,
    errorCallback: (message: string) => void
  ) => {
    const payload = {
      url: authConfig.registerEndpoint,
      body: params,
      method: 'POST'
    }

    triggerRegister(payload, {
      onSuccess(data) {
        if (data.success) {
          successCallback()

          setTimeout(() => {
            router.replace(getHomeRoute(''))
          }, 1000)
        } else {
          if (data.errors) errorCallback(data.errors)
          else errorCallback('unknown error, contact the administrator')
        }
      },
      onError: err => {
        errorCallback(err.message)
      }
    })

    // axios
    //   .post(authConfig.registerEndpoint, params)
    //   .then(res => {
    //     if (res.data.status == 1) {
    //       if (successCallback) successCallback()
    //     } else {
    //       throw new Error(res.data.message)
    //     }
    //   })
    //   .catch((message) => (errorCallback ? errorCallback(message) : null))
  }

  const updateLocalStorage = (userData: UserDataType | null) => {
    window.localStorage.setItem(authConfig.storageKeyName, encrypt(JSON.stringify({ ...userData })))
  }

  const values = {
    user,
    loading,
    setUser,
    setLoading,
    isInitialized,
    setIsInitialized,
    login: HandleLogin,
    logout: handleLogout,
    register: handleRegister,
    getHomeRoute: getHomeRoute,
    updateLocalStorage: updateLocalStorage
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
