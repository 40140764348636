// ** React Imports

// ** Next Imports

// ** MUI Imports

// ** Icons Imports

// ** Third Party Imports

// ** Axios

// ** Hook Imports
import useCrypto from './useCrypto'

// ** Configs

// ** Custom Imports

interface Payload {
  url: string
  body: any
}

// const FetchWithPayload = async ([url, payload]: [url: string, payload: Payload]) => {
//   const { encryptURI, decryptURI } = useCrypto()

//   const encryptedPayload =
//     process.env.NEXT_PUBLIC_NODE_ENV == 'development' ? JSON.stringify(payload) : encryptURI(JSON.stringify(payload))

//   const res = await fetch(url, {
//     method: 'POST',
//     body: encryptedPayload,
//     headers: { 'Content-Type': 'text/plain;charset=UTF-8' }
//   })
//   const data = await res.text()
//   const decryptedData = process.env.NEXT_PUBLIC_NODE_ENV == 'development' ? data : decryptURI(data)

//   return JSON.parse(decryptedData)
// }

// const ReqWithPayload = async (url: string, { arg }: { arg: any }) => {
//   const { encryptURI, decryptURI } = useCrypto()

//   const encryptedPayload =
//     process.env.NEXT_PUBLIC_NODE_ENV == 'development' ? JSON.stringify(arg) : encryptURI(JSON.stringify(arg))
//   const res = await fetch(url, {
//     method: 'POST',
//     body: encryptedPayload,
//     headers: { 'Content-Type': 'text/plain;charset=UTF-8' }
//   })
//   const data = await res.text()
//   const decryptedData = process.env.NEXT_PUBLIC_NODE_ENV == 'development' ? data : decryptURI(data)

//   return JSON.parse(decryptedData)
// }

const FetchWithPayload = async ([url, payload]: [url: string, payload: Payload]) => {
  const { encryptURI, decryptURI } = useCrypto()

  const encryptedPayload = encryptURI(JSON.stringify(payload))

  const res = await fetch(url, {
    method: 'POST',
    body: encryptedPayload,
    headers: { 'Content-Type': 'text/plain;charset=UTF-8' }
  })
  const data = await res.text()
  const decryptedData = decryptURI(data)

  return JSON.parse(decryptedData)
}

const ReqWithPayload = async (url: string, { arg }: { arg: any }) => {
  const { encryptURI, decryptURI } = useCrypto()

  const encryptedPayload = encryptURI(JSON.stringify(arg))

  const res = await fetch(url, {
    method: 'POST',
    body: encryptedPayload,
    headers: { 'Content-Type': 'text/plain;charset=UTF-8' }
  })
  const data = await res.text()
  const decryptedData = decryptURI(data)

  return JSON.parse(decryptedData)
}

const useFetch = () => {
  return { FetchWithPayload, ReqWithPayload }
}

export default useFetch
