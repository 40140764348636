import { API_URL } from './backend-url'

const endpoints = {
  authEndpoint: `${API_URL}/check-authentification`,
  loginEndpoint: `${API_URL}/login`,
  logoutEndPoint: `${API_URL}/logout`,
  registerEndpoint: `${API_URL}/registrasi`,
  usernameIsValidEndPoint: `${API_URL}/registrasi/username`,
  NIKIsValidEndPoint: `${API_URL}/registrasi/nik`,
  EmailIsValidEndPoint: `${API_URL}/registrasi/email`,
  ResetPasswordValidEndPoint: `${API_URL}/reset-password/verifikasi-pelamar`,
  ChangePasswordEndPoint: `${API_URL}/update-password`,
  storageTokenKeyName: 'ActiveToken',
  storageActiveUserIDKeyName: 'ActiveUserID',
  storageKeyName: 'E51B2B8A77519'
}

export default endpoints
