import { ReactNode, createContext, useContext, useState } from 'react'

export const TourContext = createContext({
  tourStart: false,
  setTourStart: (tourStart: boolean) => {
    tourStart
  }
})

export const TourProvider = ({ children }: { children: ReactNode }) => {
  const [tourStart, setTourStart] = useState(false)

  return <TourContext.Provider value={{ tourStart, setTourStart }}>{children}</TourContext.Provider>
}

export const useTourStart = () => {
  const context = useContext(TourContext)

  if (!context) {
    throw new Error('useTourStart must be used inside the TourProvider')
  }

  return context
}
