import LZString from 'lz-string'

// http://10.30.100.137/karir/public/api
// export const API_URL = LZString.decompressFromEncodedURIComponent(
//   'BYFxAcC4HpoRgAwDoDMzHpQdmgawIYBOAlodOAK4BGANsQMbT7jFA'
// )

// http://localhost/karir/public/api
export const API_URL = LZString.decompressFromEncodedURIComponent('BYFxAcC4HpoGwPYGMCGdgIM4mgaxQE4CWB04ArgEZxFLQrhFA')

// http://10.30.100.137/karir/public/storage
// export const STORAGE_URL = LZString.decompressFromEncodedURIComponent(
//   'BYFxAcC4HpoRgAwDoDMzHpQdmgawIYBOAlodOAK4BGANsQMbQDOIA9ofgOYCmQA'
// )

// http://10.30.100.137/karir/public/storage
export const STORAGE_URL_DB = LZString.decompressFromEncodedURIComponent(
  'BYFxAcC4HpoRgAwDoDMzHpQdmgawIYBOAlodOAK4BGANsQMbQDOIA9ofgOYCmQA'
)

// http://localhost/karir/public/storage
export const STORAGE_URL = LZString.decompressFromEncodedURIComponent(
  'BYFxAcC4HpoGwPYGMCGdgIM4mgaxQE4CWB04ArgEZxFLTYIEoDmApkA'
)

// https://career.bankaltimtara.co.id/proxy
// export const HOST_URL = LZString.decompressFromEncodedURIComponent(
//   'BYFxAcGcC4HpYMYEMBOBTNKB0AjJA7AayQBsQBLAWxFSSwQHstyATWcFBgDwE8g'
// )

// http://10.30.100.137:3000/proxy
// export const HOST_URL = LZString.decompressFromEncodedURIComponent('BYFxAcC4HpoRgAwDoDMzHpQdkmhDpwAnAewA8BPIA')

// export const HOST_URL =
//   process.env.NODE_ENV == 'development'
//     ? LZString.decompressFromEncodedURIComponent('BYFxAcC4HpoRgAwDoDMzHpQdkmhDpwAnAewA8BPIA')
//     : LZString.decompressFromEncodedURIComponent('BYFxAcGcC4HpYMYEMBOBTNKB0AjJA7AayQBsQBLAWxFSSwQHstyATWcFBgDwE8g')
