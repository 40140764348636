import { API_URL } from './backend-url'

const endpoints = {
  pelamarGetEndpoint: `${API_URL}/pelamar`,

  photoGetEndpoint: `${API_URL}/`,
  photoEndpoint: `${API_URL}/user/upload-photo`,

  photoBase64GetEndpoint: `${API_URL}/assets/getPhotoBase64/`,

  nikNamaGetEndpoint: `${API_URL}/`,
  nikNamaSetEndpoint: `${API_URL}/pelamar/nama-lengkap-nik`,

  identitasSetEndpoint: `${API_URL}/pelamar/identitas-diri`,
  identitasSetEndpointV2: `${API_URL}/pelamar/identitas-diri/V2`,

  dataLainGetEndpoint: `${API_URL}/curriculumvitae/read/data-lainnya/`,
  dataLainSetEndpoint: `${API_URL}/pelamar/data-lainnya`,

  socialMediaGetEndpoint: `${API_URL}/pelamar/sosial-media/`,
  socialMediaSetEndpoint: `${API_URL}/pelamar/sosial-media/`,
  socialMediaDelEndpoint: `${API_URL}/pelamar/sosial-media/`,
  socialMediaEndpoint: `${API_URL}/pelamar/sosial-media`,

  tentangSayaSetEndpoint: `${API_URL}/pelamar/tentang-saya`,

  pendidikanEndpoint: `${API_URL}/pelamar/pendidikan`,

  pengalamanKerjaEndpoint: `${API_URL}/pelamar/pengalaman-kerja`,

  organisasiEndpoint: `${API_URL}/pelamar/pengalaman-organisasi`,

  sertifikasiEndpoint: `${API_URL}/pelamar/sertifikasi`,

  prestasiEndpoint: `${API_URL}/pelamar/prestasi`,

  bahasaEndpoint: `${API_URL}/pelamar/bahasa`,

  riwayatMelamarEndpoint: `${API_URL}/pelamar/riwayat-melamar`,

  keluargaEndpoint: `${API_URL}/pelamar/keluarga`,

  keahlianEndpoint: `${API_URL}/pelamar/keahlian`,

  file_ktp_Endpoint: `${API_URL}/pelamar/upload-ktp`,
  file_kk_Endpoint: `${API_URL}/pelamar/upload-kk`,
  file_kelulusan_Endpoint: `${API_URL}/pelamar/upload-kelulusan`,
  file_nilai_kelulusan_Endpoint: `${API_URL}/pelamar/upload-nilai-kelulusan`,
  file_skck_Endpoint: `${API_URL}/pelamar/upload-skck`,
  file_surat_rekomendasi_Endpoint: `${API_URL}/pelamar/upload-surat-rekomendasi`,
  file_tambahan_Endpoint: `${API_URL}/pelamar/upload-tambahan`,

  pelamarBlacklistSetEndpoint: `${API_URL}/user/write/flag-blacklist/`,

  kelengkapanRiwayatEndpoint: `${API_URL}/pelamar/dashboard/riwayat-hidup`,

  finishedOnboardingEndpoint: `${API_URL}/finish-onboarding`,

  pelamarSetCekRiwayatHidupEndpoint: `${API_URL}/pelamar/cek-riwayat-hidup-json`,
  pelamarSetValidasiRiwayatHidupEndpoint: `${API_URL}/pelamar/validasi-riwayat-hidup-json`,

  kunciRiwayatHidupEndpoint: `${API_URL}/pelamar/riwayat-hidup/status`
}

export default endpoints
