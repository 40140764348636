// ** MUI Import
import Box from '@mui/material/Box'
import { createSvgIcon } from '@mui/material/utils'

const Logo = createSvgIcon(
  <>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7874.02 7874.02" shapeRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd">
      <g fill="#f5c412" fillOpacity='0.8'>
        <path d="M524.6 5908.56c-73.6-127.49-107.49-267.02-105.51-404.24v-3128.42c-1.98-137.22 31.91-276.75 105.51-404.24 217.41-376.59 698.97-505.62 1075.56-288.21 256.31 148 397.88 418.29 393.69 694.59v3124.13c4.19 276.31-137.38 546.6-393.69 694.59-376.59 217.41-858.15 88.37-1075.56-288.21zM7343.48 5908.52c-73.61 127.49-177.5 226.6-297.33 293.5l-2709.29 1564.2c-117.85 70.33-255.62 110.74-402.83 110.75-434.84.01-787.37-352.519999999999-787.38-787.35.02-295.97 163.31-553.72 404.69-688.24L6256.92 4839.31c237.2-141.78 542.06-154.32 798.38-6.35 376.58 217.43 505.61 698.99 288.18 1075.57z" />
        <path d="M3934.01 7876.99c-147.22 0-284.99-40.42-402.84-110.75L821.88 6202.03c-119.83-66.89-223.72-166-297.33-293.49-217.43-376.58-88.4-858.14 288.18-1075.57 256.32-147.97 561.19-135.43 798.38 6.35l2705.580000000001 1562.060000000001c241.39 134.53 404.68 392.28 404.69 688.24-.01 434.84-352.54 787.37-787.38 787.36z" />
      </g>
      <g fill="#1a6bd7">
        <path d="M524.56 2759.01c73.61 127.49 177.5 226.6 297.33 293.49l2709.29 1564.21c117.85 70.33 255.62 110.74 402.83 110.75 434.84.01 787.37-352.519999999999 787.38-787.36-.02-295.97-163.31-553.72-404.69-688.24L1611.12 1689.8c-237.2-141.78-542.06-154.33-798.38-6.35-376.58 217.43-505.61 698.99-288.18 1075.57z" fillOpacity='0.8' />
        <path d="M7343.48 2759.01c-73.61 127.49-177.5 226.6-297.33 293.49L4336.86 4616.71c-117.85 70.33-255.62 110.74-402.83 110.75-434.84.01-787.37-352.519999999999-787.38-787.36.02-295.97 163.31-553.72 404.69-688.24L6256.92 1689.8c237.2-141.78 542.06-154.33 798.38-6.35 376.58 217.43 505.61 698.99 288.18 1075.57z" fillOpacity='0.8' />
        <circle transform="matrix(3.69014 -3.69014 3.69014 3.69014 3934.02 3940.08)" r="150.88" />
      </g>
      <g fill="#f5c412">
        <circle cx="3934.02" cy="7089.6" r="787.38" />
        <circle transform="matrix(2.60933 -4.51949 -4.51949 -2.60933 6661.58 5514.84)" r="150.88" />
        <circle transform="matrix(-2.60933 -4.51949 4.51949 -2.60933 1206.46 5514.84)" r="150.88" />
      </g>
      <g fill="#1a6bd7">
        <path d="M6661.58 3152.71c-147.21 0-284.99-40.42-402.83-110.75l-2709.29-1564.2c-119.83-66.89-223.72-166-297.33-293.5-217.43-376.58-88.4-858.14 288.18-1075.57 256.32-147.97 561.19-135.43 798.38 6.35l2705.58 1562.07c241.38 134.53 404.68 392.28 404.69 688.24-.01 434.84-352.54 787.37-787.38 787.35z" fillOpacity='0.8' />
        <path d="M4615.92 1184.17c-73.61 127.49-177.5 226.6-297.33 293.49L1609.3 3041.87c-117.85 70.33-255.62 110.74-402.83 110.75-434.84.01-787.37-352.52-787.38-787.36.02-295.97 163.31-553.72 404.69-688.24L3529.36 114.96c237.19-141.78 542.06-154.33 798.38-6.35 376.58 217.43 505.61 698.99 288.18 1075.57z" fillOpacity='0.8' />
        <circle transform="matrix(2.60933 -4.51949 -4.51949 -2.60933 1206.46 2365.32)" r="150.88" />
        <circle cx="3934.02" cy="790.56" r="787.38" />
        <circle transform="matrix(-2.60933 -4.51949 4.51949 -2.60933 6661.58 2365.32)" r="150.88" />
      </g>
    </svg>

  </>,
  'Logo',
)

const FallbackSpinner = () => {

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0, left: 0,
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: theme => theme.palette.background.paper,
        zIndex: 99999,
      }}
    >
      <Box className='fallbackSpinner' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }} >
        <Logo sx={{ fontSize: 65, }} />
      </Box>
    </Box>
  )
}


export default FallbackSpinner
