// ** React Imports
import { useState, SyntheticEvent, Fragment, useContext } from 'react'

// ** Next Import
import { useRouter } from 'next/router'

// ** MUI Imports
import Box, { BoxProps } from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import Badge from '@mui/material/Badge'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

// ** Icons Imports
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import LockRoundedIcon from '@mui/icons-material/LockRounded'
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded'

// ** Context
import { AuthContext } from 'src/context/AuthContext'

// ** Type Imports
import { Settings } from 'src/@core/context/settingsContext'

interface Props {
  settings: Settings
}

// ** Styled Components
const BadgeContentSpan = styled('span')(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
}))

const UserDropdown = (props: Props) => {
  // ** Props
  const { settings } = props

  // ** States
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  // ** Hooks
  const router = useRouter()
  const { logout, user, getHomeRoute } = useContext(AuthContext)

  // ** Vars
  const { direction } = settings

  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = (url?: string) => {
    if (url) {
      router.push(url)
    }
    setAnchorEl(null)
  }

  const StyledBox = styled(Box)<BoxProps>(() => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  }))

  const handleLogout = () => {
    logout()
    handleDropdownClose()
  }

  return (
    <Fragment>
      <Badge
        overlap='circular'
        onClick={handleDropdownOpen}
        sx={{ ml: 0, cursor: 'pointer' }}
        badgeContent={<BadgeContentSpan />}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <Avatar alt='John Doe' onClick={handleDropdownOpen} sx={{ width: 40, height: 40 }} src={user?.user_photo}>
          <PersonRoundedIcon />
        </Avatar>
      </Badge>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        sx={{
          '& .MuiMenu-paper': { width: 300, marginTop: 4, borderRadius: 1, px: 4, pt: 4, pb: 2, boxShadow: 2 },
          '& li:hover': {
            borderRadius: 0.5,
            backgroundColor: theme => theme.myColor?.dashboardMenuBg
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: direction === 'ltr' ? 'right' : 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: direction === 'ltr' ? 'right' : 'left'
        }}
      >
        <Box sx={{ pb: 4, px: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Badge
              overlap='circular'
              badgeContent={<BadgeContentSpan />}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
            >
              <Avatar alt={user?.nama_lengkap} sx={{ width: '2.5rem', height: '2.5rem' }} src={user?.user_photo}>
                <PersonRoundedIcon />
              </Avatar>
            </Badge>
            <Box
              sx={{
                display: 'flex',
                marginLeft: 3,
                alignItems: 'flex-start',
                flexDirection: 'column',
                whiteSpace: 'nowrap'
              }}
            >
              <Typography sx={{ fontWeight: 600, overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 161 }}>
                {user?.nama_lengkap}
              </Typography>
              <Typography variant='body2' noWrap sx={{ fontSize: '0.8rem', color: 'text.disabled', width: 200 }}>
                {user?.role.role}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ mt: 0, mb: 2, mx: 1 }} />
        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose(getHomeRoute(''))}>
          <StyledBox sx={{ px: 2, py: 2, color: theme => theme.myColor?.dashboardTextColor }}>
            <DashboardRoundedIcon sx={{ marginRight: 2 }} />
            Dashboard
          </StyledBox>
        </MenuItem>
        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose()}>
          <StyledBox sx={{ px: 2, py: 2, color: theme => theme.myColor?.dashboardTextColor }}>
            <LockRoundedIcon sx={{ marginRight: 2 }} />
            Ubah Password
          </StyledBox>
        </MenuItem>
        <MenuItem sx={{ p: 0 }} onClick={handleLogout}>
          <StyledBox sx={{ px: 2, py: 2, color: theme => theme.myColor?.dashboardTextColor }}>
            <LogoutRoundedIcon sx={{ marginRight: 2 }} />
            Logout
          </StyledBox>
        </MenuItem>
      </Menu>
    </Fragment>
  )
}

export default UserDropdown
