// ** Icon imports
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded'
import PersonPinRoundedIcon from '@mui/icons-material/PersonPinRounded'
import WorkRoundedIcon from '@mui/icons-material/WorkRounded'
import GroupRoundedIcon from '@mui/icons-material/GroupRounded'

// import MailRoundedIcon from '@mui/icons-material/MailRounded'
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded'
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded'

// ** Type import
import { VerticalNavItemsType } from 'src/@core/layouts/types'

const navigation = (role: string | undefined): VerticalNavItemsType => {
  if (role == '2')
    return [
      {
        title: 'Dashboard',
        icon: DashboardRoundedIcon,
        path: '/pelamar/dashboard',
        id: 'dashboardNav'
      },

      // {
      //   title: 'Kotak Masuk',
      //   icon: MailRoundedIcon,
      //   id: 'mailNav',
      //   disabled: true

      //   // path: '/mail',
      // },
      {
        title: 'Riwayat Hidup',
        icon: PersonPinRoundedIcon,
        path: '/pelamar/riwayat-hidup',
        id: 'riwayatHidupNav'
      },

      {
        title: 'Lamaran Saya',
        icon: AssignmentRoundedIcon,
        path: '/pelamar/lamaran-saya',
        id: 'lamaranNav',
        badgeColor: 'error',
        badgeContent: 'new'
      },
      {
        title: 'Rekrutmen',
        icon: WorkRoundedIcon,
        path: '/pelamar/rekrutmen',
        id: 'rekrutmenNav'
      },
      {
        title: 'Pengumuman',
        icon: CampaignRoundedIcon,
        id: 'pengumumanNav',
        path: '/pelamar/pengumuman'

        // badgeColor: 'info',
        // badgeContent: 'new'
      }
    ]
  else if (role == '4' || role == '5')
    return [
      {
        title: 'Dashboard',
        icon: DashboardRoundedIcon,
        path: '/admin/dashboard'
      },

      // {
      //   title: 'Data Referensi',
      //   icon: ViewListRoundedIcon,
      //   children: [
      //     {
      //       title: 'Alamat',
      //       path: '/admin/alamat'
      //     },
      //     {
      //       title: 'Klasifikasi Rekrutmen',
      //       path: '/admin/klasifikasi'
      //     }
      //   ]
      // },
      // {
      //   title: 'Pengguna',
      //   icon: GroupRoundedIcon,
      //   children: [
      //     {
      //       title: 'Pelamar',
      //       path: '/admin/pelamar'
      //     },
      //     {
      //       title: 'Penilai',
      //       path: '/admin/penilai'
      //     }
      //   ]
      // },
      {
        title: 'Pelamar',
        icon: GroupRoundedIcon,
        path: '/admin/pelamar'
      },

      // {
      //   title: 'Rekrutmen',
      //   icon: WorkRoundedIcon,
      //   path: '/admin/rekrutmen'
      // }

      {
        title: 'Rekrutmen',
        icon: WorkRoundedIcon,
        children: [
          {
            title: 'Tambah Rekrutmen',
            path: '/admin/rekrutmen/tambah'
          },
          {
            title: 'List Rekrutmen',
            path: '/admin/rekrutmen'
          }
        ]
      }

      // {
      //   title: 'Pengumuman',
      //   icon: CampaignRoundedIcon,
      //   path: '/admin/pengumumam'
      // }
    ]
  else return []
}

export default navigation
