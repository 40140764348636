// ** MUI Imports
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

// import { Theme } from '@mui/material/styles'
// import useMediaQuery from '@mui/material/useMediaQuery'

const FooterContent = () => {
  // ** Var
  // const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'end', justifyContent: 'end', }}>

      <Typography variant='subtitle2' textAlign='center' sx={{ mr: 2, fontWeight:600 }}>
        {`©${new Date().getFullYear()} • Powered by Divisi Human Capital `}
        <Link target='_blank' href='https://bankaltimtara.co.id/'>
          PT BPD Kaltim Kaltara
        </Link>
      </Typography>
    </Box>
  )
}

export default FooterContent
