// ** React Imports
// import { useState } from 'react'

// ** Next Imports
import Head from 'next/head'
import { Router } from 'next/router'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'

// ** MUI Imports
import Box from '@mui/material/Box'

// ** Loader Imports
import NProgress from 'nprogress'

// ** Emotion Imports
import { CacheProvider } from '@emotion/react'
import type { EmotionCache } from '@emotion/cache'

// ** Config Imports

import { defaultACLObj } from 'src/configs/acl'
import themeConfig from 'src/configs/themeConfig'

// ** Third Party Import
import { ToastContainer } from 'react-toastify'
import { AnimatePresence, motion } from 'framer-motion'

// ** Component Imports
import UserLayout from 'src/layouts/UserLayout'
import ThemeComponent from 'src/@core/theme/ThemeComponent'
import Guard from 'src/components/Guard/Guard'

// import AuthGuard from 'src/@core/components/auth/AuthGuard'
// import GuestGuard from 'src/@core/components/auth/GuestGuard'
// import AclGuard from 'src/@core/components/auth/AclGuard'

// import WindowWrapper from 'src/@core/components/window-wrapper'

// ** Icon Imports
import IconButton from '@mui/material/IconButton'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'

// ** Spinner Import
// import Spinner from 'src/@core/components/spinner'
// import Spinner from 'src/components/Spinner'

// ** Contexts
import { AuthProvider } from 'src/context/AuthContext'
import { SettingsConsumer, SettingsProvider } from 'src/@core/context/settingsContext'
import { TourProvider } from 'src/context/TourContext'
import { CorectionProvider } from 'src/context/CorectionContext'

// ** Utils Imports
import { createEmotionCache } from 'src/@core/utils/create-emotion-cache'

// ** React Perfect Scrollbar Style
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'react-toastify/dist/ReactToastify.min.css'

// ** Global css styles
import '../../styles/globals.css'

// ** Hooks

// ** Types
// import type { ACLObj } from 'src/configs/acl'

// ** Extend App Props with Emotion
type ExtendedAppProps = AppProps & {
  Component: NextPage
  emotionCache: EmotionCache

  // pageProps: { auth?: boolean; session?: Session; }
}

// type GuardProps = {
//   authGuard: boolean
//   guestGuard: boolean
//   children: ReactNode
//   aclAbilities: ACLObj
// }

const clientSideEmotionCache = createEmotionCache()

// const Guard = ({ children, authGuard, guestGuard, aclAbilities }: GuardProps) => {
//   if (guestGuard && !authGuard) {
//     // Guest OK, LoggedIn NO
//     return <GuestGuard fallback={<Spinner />}>{children}</GuestGuard>
//   } else if (!guestGuard && authGuard) {
//     // Guest NO, LoggedIn OK
//     return (
//       <AuthGuard fallback={<Spinner />}>
//         <AclGuard aclAbilities={aclAbilities} guestGuard={guestGuard}>
//           {children}
//         </AclGuard>
//       </AuthGuard>
//     )
//   } else {
//     // Guest OK, LoggedIn OK
//     return <>{children}</>
//   }
// }

// ** Configure JSS & ClassName
const App = (props: ExtendedAppProps) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps, router } = props

  // const App = ({ Component, emotionCache = clientSideEmotionCache, pageProps:{ session, ...pageProps} , router }: ExtendedAppProps) => {

  // Variables
  const getLayout = Component.getLayout ?? (page => <UserLayout>{page}</UserLayout>)

  const setConfig = Component.setConfig ?? undefined

  const authGuard = Component.authGuard ?? true

  const guestGuard = Component.guestGuard ?? false

  const aclAbilities = Component.acl ?? defaultACLObj

  const animationVariants = {
    start: {
      opacity: 0,
      transition: {
        duration: 0.5,
        ease: 'easeInOut'
      }
    },
    enter: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeInOut'
      }
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.5,
        ease: 'easeInOut'
      }
    }
  }

  // ** Pace Loader
  if (themeConfig.routingLoader) {
    Router.events.on('routeChangeStart', () => {
      NProgress.start()
    })
    Router.events.on('routeChangeError', () => {
      NProgress.done()
    })
    Router.events.on('routeChangeComplete', () => {
      NProgress.done()
    })
  }

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>{`Bankaltimtara Career`}</title>
        <meta name='description' content={`Bankaltimtara Career`} />
        <meta name='keywords' content={`career, recruitment, bankaltimtara`} />
        <meta name='viewport' content='initial-scale=1, width=device-width' />
      </Head>

      <AuthProvider>
        <SettingsProvider {...(setConfig ? { pageSettings: setConfig() } : {})}>
          <SettingsConsumer>
            {({ settings }) => {
              return (
                <ThemeComponent settings={settings}>
                  <CorectionProvider>
                    <TourProvider>
                      {/* <WindowWrapper> */}
                      <AnimatePresence mode='popLayout'>
                        <motion.div
                          key={router.pathname}
                          variants={animationVariants}
                          initial='start'
                          animate='enter'
                          exit='exit'
                          transition={{ type: 'spring' }}
                        >
                          <Guard aclAbilities={aclAbilities} authGuard={authGuard} guestGuard={guestGuard}>
                            {getLayout(<Component {...pageProps} />)}
                          </Guard>
                        </motion.div>
                      </AnimatePresence>

                      {/* </WindowWrapper> */}
                      <Box
                        sx={{
                          '& .Toastify__toast-container': {
                            top: 80,
                            '& .Toastify__toast': {
                              borderRadius: 1,
                              backgroundColor: theme => theme.palette.background.paper,
                              boxShadow: 5,
                              '@media (max-width: 480px)': {
                                // border: theme => `1px solid ${theme.myColor?.colorToggle}`
                                // backgroundColor: theme => theme.myColor?.colorToggleLight
                                boxShadow: 11
                              }
                            },
                            '@media (max-width: 480px)': {
                              paddingX: 4
                            }
                          }
                        }}
                      >
                        <ToastContainer
                          position={settings.toastPosition}
                          autoClose={3000}
                          theme={settings.mode}
                          closeButton={({ closeToast }) => {
                            return (
                              <IconButton
                                onClick={e => closeToast(e)}
                                sx={{
                                  p: 0,
                                  width: 20,
                                  height: 20,
                                  '&:hover': {
                                    color: theme => theme.palette.error.main,
                                    backgroundColor: theme => theme.palette.error.light
                                  }
                                }}
                              >
                                <HighlightOffRoundedIcon sx={{ width: 15, height: 15 }} />
                              </IconButton>
                            )
                          }}
                        />
                      </Box>
                    </TourProvider>
                  </CorectionProvider>
                </ThemeComponent>
              )
            }}
          </SettingsConsumer>
        </SettingsProvider>
      </AuthProvider>
    </CacheProvider>
  )
}

export default App
