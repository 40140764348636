import { ReactNode, createContext, useContext, useState } from 'react'

export const CorectionContext = createContext({
  corectionStart: false,
  setCorectionStart: (corectionStart: boolean) => {
    corectionStart
  },
  showCorectionModal: false,
  setCorectionShowModal: (showCorectionModal: boolean) => {
    showCorectionModal
  }
})

export const CorectionProvider = ({ children }: { children: ReactNode }) => {
  const [corectionStart, setCorectionStart] = useState(false)
  const [showCorectionModal, setCorectionShowModal] = useState(false)

  return (
    <CorectionContext.Provider value={{ corectionStart, setCorectionStart, showCorectionModal, setCorectionShowModal }}>
      {children}
    </CorectionContext.Provider>
  )
}

export const useCorectionStart = () => {
  const context = useContext(CorectionContext)

  if (!context) {
    throw new Error('useCorectionStart must be used inside the CorectionProvider')
  }

  return context
}
